import axios from "axios";
import { LocalStorageWrapper } from "../helper/localStorageWrapper";

export const API_ORIGIN = process.env.VUE_APP_CORE_BACKEND_URL;
export const BASE_API_URL = API_ORIGIN + "/api/v1";

const storage = new LocalStorageWrapper();

export const authInterceptor = [
  (config) => {
    if (storage.getItem("VUE_APP_TOKEN")) {
      config.headers.Authorization = `Bearer ${storage.getItem(
        "VUE_APP_TOKEN"
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
];

export const timezoneInterceptor = [
  (config) => {
    if (
      storage.getItem("userTimeZone") &&
      storage.getItem("userTimeZone") !== "null"
    ) {
      config.headers["X-Timezone"] = storage.getItem("userTimeZone");
    }
    return config;
  },
  (error) => Promise.reject(error),
];

export const authorizedReq = axios.create();

authorizedReq.interceptors.request.use(...authInterceptor);
authorizedReq.interceptors.request.use(...timezoneInterceptor);
